import { Workbox } from 'workbox-window';

if ('serviceWorker' in navigator) {
    const wb = new Workbox('/serviceworker.js');

    wb.register().then(function (registration) {
        if (process.env.NODE_ENV === 'development') {
            console.log('django-pwa: ServiceWorker registration successful with scope: ', registration.scope);
        }
    }, function (err) {
        if (process.env.NODE_ENV === 'development') {

            console.log('django-pwa: ServiceWorker registration failed: ', err);
        }
    });;
}